<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <div class="mt-5">
      <div class="title black--text mb-2">List Users</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Users . . ."
              autocomplete="off"
              v-model="searching"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              @click="showFilter = !showFilter"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize ml-5">
              <span v-if="showFilter">Batalkan</span>
              <span v-else>Filter</span>
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-col v-if="showFilter" cols="12">
          <v-row class="">
            <v-col>
              <v-text-field
                v-model="filter.email"
                dense
                outlined
                placeholder="Email"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="filter.provider"
                :items="providerItems"
                dense
                outlined
                item-text="text"
                item-value="value"
                placeholder="Provider"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu.min_register"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.min_register"
                    label="Tanggal Min Register"
                    color="#F05326"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#F05326"
                  v-model="filter.min_register"
                  @input="menu.min_register = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menu.max_register"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.max_register"
                    label="Tanggal Max Register"
                    color="#F05326"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#F05326"
                  v-model="filter.max_register"
                  @input="menu.max_register = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn depressed class="mr-3" color="#F05326" dark @click="fetch()">
                Search
              </v-btn>
              <v-btn depressed @click="fetch(); filter = {}">
                Reset
              </v-btn>
              <v-btn
                v-show="Object.keys(filter).length > 0"
                color="teal"
                class="white--text ml-3"
                depressed>
                <v-icon left>mdi-download</v-icon>
                <vue-excel-xlsx
                  :data="items.list"
                  :columns="columnsExcel"
                  :file-name="'Data User'"
                  :file-type="'xlsx'"
                  :sheet-name="'Data User'">
                  Export Excel
                </vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="props.item.picture" style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="props.item.picture">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                     <v-list-item-avatar v-else style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="require('@/assets/img/profile_picture.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <!-- <v-list-item-content>
                      <v-list-item-title class="caption font-weight-bold text-second mb-1">
                        {{ props.item.username }}
                      </v-list-item-title>
                    </v-list-item-content> -->
                  </v-list-item>
                </td>
                <td class="text-start white">
                  {{ props.item.child_name }}
                </td>
                <td class="text-start white">
                  {{ props.item.email }}
                </td>
                <td class="text-start white">
                  {{ props.item.phone }}
                </td>
                <td class="text-start white">
                  {{ props.item.gender }}
                </td>
                <td class="text-start white">
                  {{ props.item.age }}
                </td>
                <td class="text-center white">
                  -
                </td>
                <td class="text-center white">
                  {{ props.item.last_login | datetimeFull }}
                </td>
                <td class="text-center white">
                  <v-btn
                    icon
                    color="blue"
                    @click="detail(props.item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import moment from 'moment'
export default {
    components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      title:"List Users",
      showFilter: false,
      filter: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'Image',align: 'start',value: 'username',sortable: false },
        { text: 'Fullname',align: 'start',value: 'child_name',sortable: false },
        { text: 'Email',align: 'start',value: 'section_id',sortable: false },
        { text: 'Phone',align: 'start',value: 'phone',sortable: false },
        { text: 'Gender',align: 'start',value: 'gender',sortable: false },
        { text: 'Age',align: 'start',value: 'age',sortable: false },
        { text: 'Status',align: 'center',value: 'status',sortable: false },
        { text: 'Last Access',align: 'center',value: 'last_access',sortable: false },
        { text: '',align: 'end',value: 'action',sortable: false }
      ],
      items:{},
      data:[],
      searching:"",
      process: {
        run: false
      },
      menu: {
        min_register: false,
        max_register: false
      }
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
  },
  created(){
    this.fetch()
  },
  computed:{
    columnsExcel() {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Nama",
          field: "child_name",
        },
        {
          label: "Username",
          field: "username",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Jenis Kelamin",
          field: "gender",
        },
        {
          label: "Provider",
          field: "provider",
        },
        {
          label: "Alamat",
          field: "address",
        },
        {
          label: "Umur",
          field: "age",
        },
        {
          label: "Tanggal Lahir",
          field: "birthday",
        },
        {
          label: "Tempat Lahir",
          field: "birth_place",
        },
        {
          label: "Tanggal Daftar",
          field: "created_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Akses Terakhir",
          field: "last_login",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Status Berlangganan",
          field: "subscription.is_subscribed",
          dataFormat: (value) => {
            return value == true ? '✅' : '❌'
          }
        },
        {
          label: "Nama Langganan",
          field: "subscription.package.title",
        },
        {
          label: "Tanggal Berlangganan",
          field: "subscription.package.subscribe_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Tanggal Habis",
          field: "subscription.package.expired_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "License",
          field: "subsList",
          dataFormat: this.LicenseFormat,
        }
      ]
    },
    providerItems() {
      return [{ text: 'Facebook', value: 'facebook'}, { text: 'Google', value: 'google'}, { text: 'Apple', value: 'apple' }];
    },
    limitItems() {
      return [{ text: '10' }, { text: '25' }, { text: '50' }, { text: '100' }];
    },
    filterMinRegister() {
      const newDate = new Date(this.filter.min_register);
      let yesterday = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() - 1,
        17
      );
      return this.filter.min_register ?  moment(yesterday).format('YYYY-MM-DD HH:mm:ss') : '';
    },
    filterMaxRegister() {
      const newDate = new Date(this.filter.max_register);
      let yesterday = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() - 1,
        16,
        59,
        59
      );
      return this.filter.min_register ? moment(yesterday).format('YYYY-MM-DD HH:mm:ss') : '';
    }
  },
  mounted(){
  },
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true
      this.$store.state.process.run = true

      await get(`api/v1/member/list`,{
        params:{
          limit: this.limit,
          page: paging,
          provider: this.filter.provider,
          email: this.filter.email,
          min_register: this.filterMinRegister,
          max_register: this.filterMaxRegister
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.$store.state.process.run = false
        this.totalData = res.data.total
      })
    },
    detail(item){
      this.$router.push(`/users/update/${item.id}`)
    },
  }
}
</script>

<style>

</style>